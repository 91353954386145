<template>
  <div>
    <linear-loading-dialog
      v-if="isLinearLoading"
      v-model="linearProgress">
    </linear-loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <input type="file" ref="imageInput" 
      style="display: none" accept="image/*"
      @change="setImage" />
    <input type="file" ref="videoInput" 
      style="display: none" accept="video/*"
      @change="setVideo" />
    <input type="file" ref="docInput" 
      style="display: none" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document*"
      @change="setDocument" />
    <v-dialog v-model="isOpen" max-width="600px" scrollable>
      <v-card outlined>
        <v-card-title class="font-18 bold">
          {{ $vuetify.lang.t('$vuetify.posts.create_post') }}
          <v-spacer></v-spacer>
          <v-btn icon small @click="isOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2" style="height: 400px;">
          <v-slide-group
            v-if="!hasVoom"
            v-model="voomId"
            mandatory
            show-arrows>
            <v-slide-item
              v-for="(voom, key) in vooms"
              :key="key"
              :value="voom.id"
              :class="key !== 0 ? 'ms-3' : ''"
              v-slot:default="{ active, toggle }">
              <v-card width="170" class="elevation-0 py-3 px-3"
                :color="active ? 'secondary' : 'transparent'"
                :dark="active || $vuetify.theme.dark"
                @click="toggle">
                <div class="text-center">
                  <v-avatar :size="60" color="primary">
                    <img :src="voom.image !== null ? voom.image.thumb_sm : require('@/assets/no_image.jpg')" />
                  </v-avatar>
                  <div class="mt-1 font-14">
                    {{ voom.name }}
                  </div>
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div class="mt-2 font-18 red--text text--darken-1 bold" v-if="!hasVoom && vooms.length === 0 && !isLoading">
            {{ $vuetify.lang.t('$vuetify.posts.add_vooms') }}
          </div>
          <v-textarea
            class="mt-4 elevation-0"
            v-model="post_text"
            solo
            flat
            no-resize
            :placeholder="$vuetify.lang.t('$vuetify.posts.write_something')"
            hide-details>
          </v-textarea>
          <v-img
            v-if="image_file !== null"
            :src="imageUrl">
            <div style="position: absolute; right: 0; top: 0;">
              <v-btn small fab @click="cancelFiles">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-img>
          <div style="position: relative;" v-if="video_file !== null">
            <div style="position: absolute; right: 0; top: 0; z-index: 5;">
              <v-btn small fab @click="cancelFiles">
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
            <video
              :src="videoUrl"
              style="height: 300px; width: 100%;"
              controlsList="nodownload"
              controls>
            </video>
          </div>
          <div v-if="document_file !== null">
            <v-card>
              <v-card-text>
                <v-list-item>
                  <v-list-item-title class="font-16 primary--text bold">
                    {{ document_file.name }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn small icon @click="cancelFiles">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions class="mt-2">
          <v-btn icon small @click="$refs.imageInput.click()">
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn icon smalll @click="$refs.videoInput.click()">
            <v-icon>mdi-video</v-icon>
          </v-btn>
          <v-btn icon small l @click="$refs.docInput.click()">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="textTransformNone px-4"
            rounded
            :dark="form_valid || $vuetify.theme.dark"
            :disabled="!form_valid || isLoading || isLinearLoading"
            color="primary"
            @click="createPost">
            {{ $vuetify.lang.t('$vuetify.general.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      hasVoom: {
        type: Boolean,
        default: false
      },
      selectedVoomId: String
    },
    data () {
      return {
        isLoading: false,
        isLinearLoading: false,
        linearProgress: 0,
        voomId: null,
        vooms: [],
        post_text: '',
        document_file: null,
        video_file: null,
        image_file: null,
        isError: false,
        errorMessage: ''
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      voomIdToSend () {
        if (this.hasVoom) {
          return this.selectedVoomId
        }
        return this.voomId
      },
      form_valid () {
        var isValid = (this.post_text.length !== 0 || this.image_file !== null || this.video_file !== null || this.document_file !== null)
        if (this.hasVoom) {
          isValid = isValid && this.selectedVoomId !== null
        } else {
          isValid = isValid && this.voomId !== null
        }
        return isValid
      },
      imageUrl () {
        if (this.image_file === null) {
          return null
        }
        return URL.createObjectURL(this.image_file)
      },
      videoUrl () {
        if (this.video_file === null) {
          return null
        }
        return URL.createObjectURL(this.video_file)
      }
    },
    methods: {
      getVooms () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/allowed_vooms/', auth_header).then(response => {
          this.isLoading = false
          this.vooms = response.body
        }, error => {
          this.isLoading = false
        })
      },
      setImage (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = file
        this.video_file = null
        this.document_file = null
      },
      setVideo (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = null
        this.video_file = file
        this.document_file = null
      },
      setDocument (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = null
        this.video_file = null
        this.document_file = file
      },
      cancelFiles () {
        this.image_file = null
        this.video_file = null
        this.document_file = null
      },
      createPost () {
        this.isLoading = true
        var self = this
        var auth_header = {
          progress (e) {
            if (e.lengthComputable) {
              self.linearProgress = parseInt(e.loaded / e.total * 100)
              if (self.linearProgress === 100) {
                self.isLinearLoading = false
                self.isLoading = true
              }
            }
          },
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        var type = 0
        var params = {post_type: type, text: this.post_text}
        if (this.image_file !== null) {
          type = 1
          params = new FormData()
          params.set('post_type', type)
          if (this.post_text.length > 0) {
            params.set('text', this.post_text)
          }
          params.set('image_file', this.image_file)
        } else if (this.video_file !== null) {
          type = 3
          params = new FormData()
          params.set('post_type', type)
          if (this.post_text.length > 0) {
            params.set('text', this.post_text)
          }
          params.set('video_file', this.video_file)
          this.isLoading = false
          this.isLinearLoading = true
        } else if (this.document_file !== null) {
          type = 4
          params = new FormData()
          params.set('post_type', type)
          if (this.post_text.length > 0) {
            params.set('text', this.post_text)
          }
          params.set('document_file', this.document_file)
        }
        this.$http.post(this.basic_url + '/social_api/posts/?voom_id=' + this.voomIdToSend, params, auth_header).then(response => {
          this.isLoading = false
          this.isLinearLoading = false
          this.isOpen = false
          this.resetData()
          this.$emit('post-created')
        }, error => {
          this.isLoading = false
          this.isLinearLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
        })
      },
      resetData () {
        this.post_text = ''
        this.image_file = null
        this.video_file = null
        this.document_file = null
      }
    },
    created () {
      if (!this.hasVoom) {
        this.getVooms()
      }
    }
  }
</script>