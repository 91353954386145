<template>
  <v-dialog v-model="isOpen" max-width="900px" :fullscreen="isFullscreen">
    <v-card class="px-7 py-7" dark>
      <v-btn small large icon @click="isOpen = false">
        <v-icon large>
          mdi-close
        </v-icon>
      </v-btn>
      <v-btn small large icon @click="isFullscreen = !isFullscreen">
        <v-icon large>
          {{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
        </v-icon>
      </v-btn>
      <v-img
        class="mt-2"
        :src="url"
        :height="isFullscreen ? '90vh' : '70vh'"
        contain>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      url: String
    },
    data () {
      return {
        isFullscreen: false
      }
    },
    watch: {
      isOpen (value) {
        if (!value) {
          this.isFullscreen = false
        }
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>