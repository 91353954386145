<template>
  <v-snackbar
    v-if="item !== null"
    v-model="isOpen"
    color="secondary"
    :timeout="5000"
    top>
    <div class="font-15 bold white--text">
      {{ item.title }}
    </div>
    {{ item.message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="viewClicked"
      >
        View
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      item: Object,
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    methods: {
      viewClicked () {
        this.isOpen = false
        this.notificationClick(this.item)
      }
    }
  }
</script>