<template>
  <v-dialog v-model="isOpen" max-width="700px">
    <v-card>
      <v-card-text class="pt-4">
        <v-card dark class="elevation-0" v-if="videoType === 0 && src !== null">
          <Player theme="dark"
            style="--vm-control-scale: 1.1;">
            <Video cross-origin="">
              <source :data-src="src" />
            </Video>
            <default-ui></default-ui>
          </Player>
        </v-card>
        <youtube
          v-if="videoType === 1 && src !== null"
          :video-id="$youtube.getIdFromURL(src)"
          @ready="ready"
          player-width="100%"
          :player-vars="{rel: 0}">    
        </youtube>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Player, Video, DefaultUi } from '@vime/vue'
  export default {
    props: {
      value: Boolean,
      src: String,
      videoType: Number
    },
    data () {
      return {
        youtubePlayer: null
      }
    },
    components: {
      Player,
      Video,
      DefaultUi
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    methods: {
      ready (event) {
        this.youtubePlayer = event.target
      }
    }
  }
</script>