<template>
  <v-container fluid>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <page-div>
      <div class="font-17 bold my-4">
        {{ $vuetify.lang.t('$vuetify.notifications.title') }}
      </div>
      <v-list v-if="notification_data !== null">
        <v-list-item v-for="(notif, key) in notification_data.data" :key="key"
          @click="notificationClick(notif)"
          :class="!notif.is_seen ? 'blue-grey darken-3' : ''" :dark="!notif.is_seen">
          <v-list-item-avatar>
            <img :src="(notif.voom !== null && notif.voom.image !== null) ? notif.voom.image.thumb_sm : require('@/assets/no_image.jpg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-15">
              {{ notif.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-14">
              {{ notif.message }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-13 bold">
              {{ timeSince(notif.created_at) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="mt-3 mb-10" v-if="notification_data !== null && notification_data.last_page > 1">
        <v-pagination
          v-model="notification_page"
          :length="notification_data.last_page"
          circle
        ></v-pagination>
      </div>
    </page-div>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        notification_data: null,
        notification_page: 1,
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    watch: {
      notification_page () {
        if (!this.isLoading) {
          this.getNotifications('page')
          window.scrollTo(0, 0)
        }
      }
    },
    methods: {
      getNotifications (type = 'reload') {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            Language: this.$store.getters.lang,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        var page_query = ''
        if (type === 'reload') {
            this.notification_page = 1
        } else {
          page_query = '?page=' + this.notification_page
        }
        this.$http.get(this.basic_url + '/social_api/notifications/' + page_query, auth_header).then(response => {
          this.isLoading = false
          this.notification_data = response.body
          if (type === 'reload') {
            this.markRead()
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      markRead () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.post(this.basic_url + '/social_api/notifications/mark_seen/', {}, auth_header).then(response => {
          this.isLoading = false
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      this.getNotifications()
    }
  }
</script>