<template>
  <v-hover v-slot:default="{ hover }">
    <v-card outlined cl :elevation="hover ? 20 : 0" :class="hover && url !== 'none' ? 'rounded-lg card_color py-4' : 'rounded-lg py-4'">
      <div v-blur="{isBlurred: hover && url !== 'none', filter: 'blur(3px)'}">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle :class="!small ? 'font-18 bold' : 'font-15 bold'">
              {{ title }}
            </v-list-item-subtitle>
            <v-list-item-title :class="!small ? 'ms-2 weight-800 primary--text font-30' : 'ms-2 weight-800 primary--text font-25'">
              {{ showText ? text : count }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar :size="!small ? 130 : 80" class="app-gradient">
            <v-img style="border-radius: unset;" :src="icon" :height="!small ? 80 : 50" contain></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </div>
      <v-overlay absolute :opacity="0.7" v-if="hover && url !== 'none'" color="transparent">
        <v-row class="fill-height" justify="center" align="center">
          <v-btn color="blue darken-3" dark :to="url" class="textTransformNone">
            {{ $vuetify.lang.t('$vuetify.dashboard.view') }}
          </v-btn>
        </v-row>
      </v-overlay>
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    props: {
      title: String,
      count: Number,
      small: {
        type: Boolean,
        default: false
      },
      showText: {
        type: Boolean,
        default: false
      },
      text: String,
      url: {
        type: String,
        default: 'none'
      },
      icon: String
    }
  }
</script>