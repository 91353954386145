<template>
  <v-container fluid>
    <page-div>
      <loading-dialog v-model="isLoading"></loading-dialog>
      <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
      <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
      <v-card outlined class="rounded-xl" v-if="profile !== null">
        <v-card-title class="font-16 bold">
          {{ $vuetify.lang.t('$vuetify.account.profile') }}
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <input type="file" ref="imageInput" 
              style="display: none" accept="image/*"
              @change="setProfileImage" />
            <v-hover v-slot:default="{ hover }">
              <v-avatar color="primary" :size="150">
                <span class="white--text font-40" v-if="imageUrl === null">
                  {{ profile.name.substring(0, 1) }}
                </span>
                <img v-else :src="imageUrl" />
                <v-overlay absolute v-if="hover">
                  <v-row class="fill-height" justify="center" align="center">
                    <v-btn
                      large
                      icon
                      @click="$refs.imageInput.click()">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </v-row>
                </v-overlay>
              </v-avatar>
            </v-hover>
          </div>
          <v-text-field
            class="mt-3"
            v-model="profile_object.name"
            :label="$vuetify.lang.t('$vuetify.account.your_name')"
            :disabled="!name_changes_enabled"
            dense
            outlined
            hide-details>
          </v-text-field>
          <v-select
            class="mt-3"
            v-model="profile_object.gender"
            :label="$vuetify.lang.t('$vuetify.account.gender.gender')"
            :items="gender_choices"
            dense
            outlined
            hide-details>
          </v-select>
          <v-text-field
            class="mt-3"
            v-model="profile_object.birth_date"
            :label="$vuetify.lang.t('$vuetify.account.birth_date')"
            type="date"
            dense
            outlined
            hide-details>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="updateProfile" :disabled="!profile_form_valid">
            {{ $vuetify.lang.t('$vuetify.general.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card outlined class="rounded-xl mt-10">
        <v-card-title class="font-16 bold">
          {{ $vuetify.lang.t('$vuetify.account.change_password') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            type="password"
            class="mt-2"
            v-model="password_object.current_password"
            :label="$vuetify.lang.t('$vuetify.account.current_password')"
            dense
            outlined
            hide-details>
          </v-text-field>
          <v-text-field
            type="password"
            class="mt-2"
            v-model="password_object.new_password"
            :label="$vuetify.lang.t('$vuetify.account.new_password')"
            dense
            outlined>
          </v-text-field>
          <v-text-field
            type="password"
            v-model="password_object.confirm_new_password"
            :label="$vuetify.lang.t('$vuetify.account.confirm_new_password')"
            dense
            outlined
            :rules="[passwords_match]">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="updatePassword" :disabled="passwords_match !== true || password_length !== true">
            {{ $vuetify.lang.t('$vuetify.general.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </page-div>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        profile: null,
        profile_object: {
          name: '',
          birth_date: null,
          gender: -1
        },
        image_file: null,
        gender_choices: [
          {text: this.$vuetify.lang.t('$vuetify.account.gender.male'), value: 1},
          {text: this.$vuetify.lang.t('$vuetify.account.gender.female'), value: 2},
          {text: this.$vuetify.lang.t('$vuetify.account.gender.rather_not_say'), value: 3}
        ],
        password_object: {
          current_password: '',
          new_password: '',
          confirm_new_password: ''
        },
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    computed: {
      name_changes_enabled () {
        if (this.$store.getters.conf !== undefined) {
          return this.$store.getters.conf.name_changes_enabled
        }
        return true
      },
      profile_form_valid () {
        return this.profile_object.name.length > 0
      },
      password_length () {
        if (this.password_object.new_password.length < 6) {
          return this.$vuetify.lang.t('$vuetify.account.password_digits')
        }
        return true
      },
      passwords_match () {
        if (this.password_object.new_password !== this.password_object.confirm_new_password) {
          return this.$vuetify.lang.t('$vuetify.account.pass_no_match')
        }
        return true
      },
      imageUrl () {
        if (this.profile === null) {
          return null
        }
        if (this.image_file !== null) {
          return URL.createObjectURL(this.image_file)
        } else {
          if (this.profile.image !== null) {
            return this.profile.image.thumb_lg
          }
        }
        return null
      }
    },
    methods: {
      setProfileImage (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = file
      },
      updateProfile () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        if (!this.profile_form_valid) {
          return
        }
        var data = Object.assign({}, this.profile_object)
        if (data.birth_date === null) {
          delete data.birth_date
        }
        var form_data = new FormData()
        form_data.set('name', data.name)
        form_data.set('gender', data.gender)
        if (this.profile_object.birth_date !== null) {
          form_data.set('birth_date', data.birth_date)
        }
        if (this.image_file !== null) {
          form_data.set('image', this.image_file)
        }
        this.$http.post(this.basic_url + '/auth/users/update_profile/', form_data, auth_header).then(response => {
          this.isLoading = false
          this.getUserInfo('success')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      getUserInfo (type = 'default') {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        this.$http.get(this.basic_url + '/auth/users/user_info/', auth_header).then(response => {
          this.isLoading = false
          this.profile = response.body.user
          this.$store.dispatch('setUser', response.body.user)
          if (type === 'success') {
            this.successMsg = this.$vuetify.lang.t('$vuetify.account.profile_success')
            this.isSuccess = true
            setTimeout(() => {
              this.$router.push('/')
            }, 500)
          } else {
            this.profile_object = Object.assign({}, {
              name: this.profile.name,
              gender: this.profile.gender,
              birth_date: this.profile.birth_date
            })
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      updatePassword () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        this.$http.post(this.basic_url + '/auth/users/update_password/', this.password_object, auth_header).then(response => {
          this.isSVLoading = false
          this.successMsg = this.$vuetify.lang.t('$vuetify.account.password_success')
          this.isSuccess = true
          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }, error => {
          this.isLoading = false
          if (error.status === 400) {
            if (error.data.current_password !== undefined) {
              this.errorMessage = this.$vuetify.lang.t('$vuetify.account.current_pass_err')
              this.isError = true
            } else if (error.data.new_password !== undefined) {
              this.errorMessage = error.data.new_password[0]
              this.isError = true
            } else {
              this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
              this.isError = true
            }
          }
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      this.getUserInfo()
    }
  }
</script>