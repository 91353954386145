<template>
  <div>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <v-dialog v-model="isOpen" max-width="600px" scrollable>
      <v-card>
        <v-card-title class="font-17 bold">
          {{ $vuetify.lang.t('$vuetify.messaging.start_conversation') }}
          <v-spacer></v-spacer>
          <v-btn icon small @click="isOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-4" style="height: 500px;">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.general.search')"
            class="mt-2"
            outlined
            dense
            @input="searchUpdated"
            rounded
            hide-details
            clearable
          ></v-text-field>
          <v-list dense>
            <v-list-item
              v-for="(user, key) in chat_users"
              :key="key"
              class="mt-2"
              :three-line="user.category_data !== null"
              @click="startConversation(user)">
              <v-list-item-avatar :size="35" color="primary">
                <span class="white--text font-16" v-if="user.image === null">
                  {{ user.name.substring(0, 1) }}
                </span>
                <img v-else :src="user.image.thumb_lg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-14">
                  {{ user.name + (user.account_type === 0 ? (' (' + $vuetify.lang.t('$vuetify.messaging.type_student') + ')') : '') }}
                </v-list-item-title>
                <v-list-item-subtitle class="font-13" v-if="user.login_field !== null">
                  {{ getLoginField(user.login_field) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-15" v-if="user.category_data !== null">
                  <v-chip class="font-13 mt-1" small>
                    <v-icon small left>mdi-category</v-icon>
                    {{ user.category_data.category.name + ' - ' + user.category_data.subcategory.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  import debounce from 'debounce'
  export default {
    props: {
      value: Boolean
    },
    data () {
      return {
        isLoading: false,
        chat_users: [],
        isError: false,
        errorMessage: ''
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
      isOpen (value) {
        if (value) {
          this.getChatUsers()
        }
      }
    },
    methods: {
      getChatUsers (search_query = '') {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        var query = (search_query !== null && search_query.length > 0) ? '?search_query=' + search_query : ''
        this.$http.get(this.basic_url + '/social_api/chat_users/' + query, auth_header).then(response => {
          this.isLoading = false
          this.chat_users = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      startConversation (user) {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.post(this.basic_url + '/social_api/conversations/', {receiver: user.id}, auth_header).then(response => {
          this.isLoading = false
          this.$emit('updated', response.body)
          this.isOpen = false
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
        })
      },
      searchUpdated: debounce(function (e) {
        this.getChatUsers(e)
      }, 500),
    }
  }
</script>