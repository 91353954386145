<template>
  <v-dialog v-model="unauthorized_dialog" max-width="400" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ $vuetify.lang.t('$vuetify.general.session_expired') }}
      </v-card-title>
      <v-card-text>
        {{ $vuetify.lang.t('$vuetify.general.session_expired_txt') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="okClicked">
          {{ $vuetify.lang.t('$vuetify.general.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    computed: {
      unauthorized_dialog: {
        get () {
          return this.$store.getters.unauthorized_dialog
        },
        set (value) {
          this.$store.dispatch('setUnauthorizedDialog', value)
        }
      }
    },
    methods: {
      okClicked () {
        this.$cookies.remove(constants.cookies.TOKEN, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.SESSION_ID, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.ED_TEAM_ID, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.ED_ACCOUNT_TYPE, null, constants.COOKIES_DOMAIN_NAME)
        window.location.href = constants.LOGIN_URL
        this.unauthorized_dialog = false
      }
    }
  }
</script>
