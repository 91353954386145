<template>
  <div>
    <div v-if="conversation !== null">
      <v-toolbar
        color="primary"
        dark
        dense>
        <v-avatar color="card_color" :size="35"
          v-if="conversation.room_type === 1">
          <span class="font-17 primary_text--text" v-if="conversation.chatting_with.image === null">
            {{ conversation.chatting_with.name.substring(0, 1) }}
          </span>
          <img v-else :src="conversation.chatting_with.image.thumb_lg" />
        </v-avatar>
        <v-avatar color="card_color" :size="35"
          v-else>
          <span class="font-17 primary_text--text" v-if="conversation.image === null">
            {{ conversation.name.substring(0, 1) }}
          </span>
          <img v-else :src="conversation.image.thumb_sm" />
        </v-avatar>
        <v-toolbar-title class="font-17 ms-3"
          style="cursor: pointer;"
          @click="isChatWindowOpen = !isChatWindowOpen">
          {{ conversation.room_type !== 1 ? conversation.name : conversation.chatting_with.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$router.push('/')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <chatting-general-component
        :value="conversation">
      </chatting-general-component>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        conversation: null
      }
    },
    computed: {
      id () {
        if (this.$route.query.cid !== undefined) {
          return this.$route.query.cid
        }
        return null
      }
    },
    methods: {
      retrieveConversation () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/conversations/' + this.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.conversation = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      if (!this.$vuetify.breakpoint.smAndDown) {
        this.$router.push('/')
        return
      }
      this.retrieveConversation()
    }
  }
</script>