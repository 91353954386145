<template>
  <div>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <input type="file" ref="imageInput" 
      style="display: none" accept="image/*"
      @change="setImage" />
    <input type="file" ref="videoInput" 
      style="display: none" accept="video/*"
      @change="setVideo" />
    <input type="file" ref="docInput" 
      style="display: none" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document*,.pdf"
      @change="setDocument" />
    <image-viewer
      v-model="imageViewer"
      :url="imageViewerUrl">
    </image-viewer>
    <div>
      <div class="text-center mt-1" v-if="isLoading">
        <v-progress-circular indeterminate :width="5" :size="50" color="primary"></v-progress-circular>
      </div>
      <div 
        :class="{'default-height': $route.name !== 'MobileChatting', 'mobile-height': $route.name === 'MobileChatting'}" 
        style="z-index: 95; overflow-y: scroll; overflow-x: hidden; margin-bottom: 140px;"
        v-chat-scroll="{always: false, smooth: true, scrollonremoved:true}"
        @scroll="handleChatScroll">
        <div class="fill-height"
          style="position: absolute; width: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 110;"
          v-if="image_file !== null || video_file !== null">
          <v-btn fab small @click="removeFiles" style="position: absolute; z-index: 120;">
            <v-icon>
              mdi-close-circle
            </v-icon>
          </v-btn>
          <div class="py-10">
            <v-img
              v-if="image_file !== null"
              :src="imageUrl">
            </v-img>
            <video
              v-if="video_file !== null"
              :src="videoUrl"
              style="height: 300px; width: 100%;"
              controlsList="nodownload"
              controls>
            </video>
          </div>
        </div>
        <v-row class="px-3">
          <v-col cols="12" v-for="(message, key) in messages" :key="key" 
            :class="{'text-end': $store.getters.user.account_id === message.sender.id, 'text-start': $store.getters.user.account_id !== message.sender.id}">
            <div class="card_color mx-2 px-5 py-2 font-15 text-start" style="min-height: 50px; display: inline-block; width: 60%;">
              <div class="bold font-14" v-if="$store.getters.user.account_id !== message.sender.id">
                {{ message.sender.name }}
              </div>
              <div v-if="message.message_type === 1">
                <v-img
                  v-if="message.image !== null"
                  :src="message.image.thumb_lg"
                  style="cursor: pointer;"
                  @click="viewImage(message.image.original)"
                  height="100">
                </v-img>
              </div>
              <div v-if="message.message_type === 2">
                <video
                  v-if="message.file !== null"
                  :src="message.file"
                  style="height: 100px; width: 100%;"
                  controlsList="nodownload"
                  controls>   
                </video>
              </div>
              <div v-if="message.message_type === 3">
                <a :href="message.file" target="_blank">
                  {{ message.file.split('/').pop().split('?')[0] }}
                </a>
              </div>
              <div v-html="detectUrls(message.text)"></div>
              <div class="bold font-14">
                {{ timeSince(message.created_at) }}
              </div>
            </div>
            <v-avatar :size="35" color="primary" style="vertical-align: top;" v-if="$store.getters.user.account_id !== message.sender.id">
              <span class="white--text font-16" v-if="message.sender.image === null">
                {{ message.sender.name.substring(0, 1) }}
              </span>
              <img v-else :src="message.sender.image.thumb_lg" />
            </v-avatar>
          </v-col>
        </v-row>
      </div>
      <div class="selection_card_color" style="position: absolute; z-index: 150; bottom: 0; width: 100%; padding: 1px;">
        <div class="px-3">
          <v-col cols="12" v-if="document_file !== null">
            <div class="primary--text bold text-center">
              {{ document_file.name }}
              <v-btn class="ms-2" icon small @click="document_file = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-textarea
            class="mt-2"
            hide-details
            outlined
            dense
            rows="2"
            row-height="15"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="sendMessage"
            no-resize
            v-model="chat_textfield"
            :label="$vuetify.lang.t('$vuetify.voom_session.type_something')">
            <template v-slot:append-outer>
              <twemoji-picker
                :emojiData="emojiDataAll"
                :emojiGroups="emojiGroups"
                :skinsSelection="false"
                :searchEmojisFeat="true"
                searchEmojiPlaceholder="Search here."
                searchEmojiNotFound="Emojis not found."
                @emojiUnicodeAdded="insertEmoji"
                isLoadingLabel="Loading...">
                <template v-slot:twemoji-picker-button>
                  <v-btn icon small>
                    <v-icon>
                      mdi-emoticon
                    </v-icon>
                  </v-btn>
                </template>    
              </twemoji-picker>
            </template>
          </v-textarea>
          <div class="my-3 text-end">
            <v-btn icon small @click="$refs.imageInput.click()">
              <v-icon>mdi-camera</v-icon>
            </v-btn>
            <v-btn icon smalll @click="$refs.videoInput.click()">
              <v-icon>mdi-video</v-icon>
            </v-btn>
            <v-btn icon small l @click="$refs.docInput.click()">
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
            <v-btn
              icon
              small
              color="secondary"
              :disabled="chat_textfield.length === 0 && image_file === null && video_file === null && document_file === null"
              @click="sendMessage">
              <v-icon :style="$vuetify.rtl ? 'transform: rotate(180deg);' : ''">mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json'
  import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json'
  export default {
    props: {
      value: Object
    },
    data () {
      return {
        isLoading: false,
        search: '',
        chatSocket: null,
        messages: [],
        didScrollToTop: false,
        chat_textfield: '',
        selected_file: null,
        document_file: null,
        video_file: null,
        image_file: null,
        conversation_access_token: null,
        imageViewer: false,
        imageViewerUrl: '',
        isError: false,
        errorMessage: ''
      }
    },
    watch: {
      didScrollToTop (value) {
        this.getMessages('load_more')
      }
    },
    computed: {
      emojiDataAll () {
        return EmojiAllData;
      },
      emojiGroups () {
        return EmojiGroups;
      },
      imageUrl () {
        if (this.image_file === null) {
          return null
        }
        return URL.createObjectURL(this.image_file)
      },
      videoUrl () {
        if (this.video_file === null) {
          return null
        }
        return URL.createObjectURL(this.video_file)
      }
    },
    methods: {
      insertEmoji (emoji) {
        this.chat_textfield += emoji
      },
      handleChatScroll (e) {
        if (e.target.scrollTop === 0) {
          this.didScrollToTop = true
        }
      },
      viewImage (url) {
        this.imageViewerUrl = url
        this.imageViewer = true
      },
      listenForMessages () {
        if (this.conversation_access_token === null) {
          return
        }
        this.chatSocket = new WebSocket(
            process.env.VUE_APP_WEBSOCKET_PROTOCOL
            + '://'
            + process.env.VUE_APP_SERVER_DNS
            + '/ws/conversation/?access_token='
            + this.conversation_access_token
        )

        this.chatSocket.onmessage = (e) => {
          const data = JSON.parse(e.data)
          if (data.message.sender.id !== this.$store.getters.user.account_id) {
            this.messages.push(data.message)
          }
        }

        this.chatSocket.onclose = (e) => {
          this.authenticateConversation()
        }
      },
      getMessages (type = 'reload') {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id,
          }
        }
        let skip = type === 'reload' ? 0 : this.messages.length
        this.$http.get(this.basic_url + '/social_api/conversations/messages/' + this.value.id + '/?skip=' + skip, auth_header).then(response => {
          this.isLoading = false
          if (type === 'reload') {
            this.messages = response.body
          } else {
            this.messages = [...response.body, ...this.messages]
            this.didScrollToTop = false
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
          if (type === 'load_more') {
            this.didScrollToTop = false
          }
        })
      },
      authenticateConversation () {
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id,
          }
        }
        this.isLoading = true
        this.$http.post(this.basic_url + '/social_api/conversations/authenticate/', {room: this.value.id}, auth_header).then(response => {
          this.isLoading = false
          this.conversation_access_token = response.body.token
          this.listenForMessages()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      setImage (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = file
        this.video_file = null
        this.document_file = null
      },
      setVideo (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = null
        this.video_file = file
        this.document_file = null
      },
      setDocument (e) {
        var files = e.target.files
        if (files.length === 0) {
          return
        }
        var file = files[0]
        this.image_file = null
        this.video_file = null
        this.document_file = file
      },
      removeFiles () {
        this.image_file = null
        this.video_file = null
        this.document_file = null
      },
      sendMessage () {
        if (this.chat_textfield.length === 0 && this.image_file === null && this.video_file === null && this.document_file === null) {
          return
        }
        // this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id,
          }
        }
        var data = new FormData()
        if (this.chat_textfield.length !== 0) {
          data.set('text', this.chat_textfield)
        }
        if (this.image_file !== null) {
          data.set('image_file', this.image_file)
        } else if (this.video_file !== null) {
          data.set('video_file', this.video_file)
        } else if (this.document_file !== null) {
          data.set('document_file', this.document_file)
        }
        this.chat_textfield = ''
        this.image_file = null
        this.video_file = null
        this.document_file = null
        this.$http.post(this.basic_url + '/social_api/conversations/messages/?room_id=' + this.value.id, data, auth_header).then(response => {
          // this.isLoading = false
          this.messages.push(response.body)
        }, error => {
          // this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    mounted () {
      this.authenticateConversation()
    },
    created () {
      this.getMessages()
    },
    destroyed () {
      if (this.chatSocket !== null) {
        this.chatSocket.close()
      }
    }
  }
</script>

<style>
  .default-height {
    height: 47vh;
  }

  .mobile-height {
    height: 70vh;
  }
</style>