<template>
  <v-dialog v-model="isOpen" max-width="400">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="onNoClicked">
          {{ $vuetify.lang.t('$vuetify.general.no') }}
        </v-btn>
        <v-btn color="success" text @click="onYesClicked">
          {{ $vuetify.lang.t('$vuetify.general.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'loading-dialog',
    props: {
      value: Boolean,
      title: String,
      text: String,
      emitDataYes: {
        type: Boolean,
        default: false
      },
      emitValueYes: String
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    methods: {
      onYesClicked () {
        if (this.emitDataYes) {
          this.$emit('yes-clicked', this.emitValueYes)
        } else {
          this.$emit('yes-clicked')
        }
        this.isOpen = false
      },
      onNoClicked () {
        this.$emit('no-clicked')
        this.isOpen = false
      }
    }
  }
</script>

<style>
  .loadingText {
    font-size: 14px;
  }
  .noOverflow{
    overflow-y: visible !important;
  }
</style>
