<template>
  <v-container fluid>
    <image-viewer
      v-model="imageViewer"
      :url="imageViewerUrl">
    </image-viewer>
    <likers-dialog
      v-model="likersDialog"
      :post-id="id">
    </likers-dialog>
    <yes-no-dialog
      v-model="deletePostDialog"
      title="Delete a Post"
      text="Are you sure you want to delete this post?"
      @yes-clicked="deletePost">
    </yes-no-dialog>
    <page-div>
      <loading-dialog v-model="isLoading"></loading-dialog>
      <v-row v-if="post !== null">
        <v-col cols="12" md="8" sm="9">
          <sv-post
            v-model="post"
            @image-click="showImage"
            @likers-click="likersDialog = true"
            @delete-click="deletePostDialog = true"
            :comments-shown="true">
          </sv-post>
        </v-col>
      </v-row>
    </page-div>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        post: null,
        likersDialog: false,
        imageViewer: false,
        imageViewerUrl: null,
        deletePostDialog: false,
      }
    },
    watch: {
      id (value) {
        this.getPost()
      }
    },
    computed: {
      id () {
        if (this.$route.query.pid === undefined) {
          return null
        }
        return parseInt(this.$route.query.pid)
      }
    },
    methods: {
      showImage (e, url) {
        this.imageViewerUrl = url
        this.imageViewer = true
      },
      getPost () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/posts/' + this.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.post = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      deletePost () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.delete(this.basic_url + '/social_api/posts/' + this.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.$router.push('/')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      this.getPost()
    }
  }
</script>