<template>
  <div>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-card outlined class="pb-3 rounded-xl" v-if="post !== null && post !== undefined">
      <v-list-item>
        <v-list-item-avatar>
          <v-avatar color="primary" :size="40">
            <span class="white--text font-17" v-if="post.user_data.image === null">
              {{ post.user_data.name.substring(0, 1) }}
            </span>
            <img v-else :src="post.user_data.image.thumb_lg" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-14 bold">
            {{ post.user_data.name }}
          </v-list-item-title>
          <span class="font-13">
            {{ timeSince(post.created_at) }}.
          </span>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu
            v-if="post.user_data.id === $store.getters.user.account_id || allowedDeletionOnly"
            bottom
            :nudge-width="80"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editPost" v-if="post.user_data.id === $store.getters.user.account_id">
                <v-list-item-content>
                  <v-list-item-title class="font-13 bold">
                    {{ $vuetify.lang.t('$vuetify.creators.activity_edit') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="$emit('delete-click')"
                v-if="post.user_data.id === $store.getters.user.account_id || allowedDeletionOnly">
                <v-list-item-content>
                  <v-list-item-title class="font-13 bold">
                    {{ $vuetify.lang.t('$vuetify.creators.activity_delete') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-card-text class="font-15 pb-0 mb-0">
        <div class="secondary_text--text" style="white-space: pre-wrap;" v-if="post.text !== null && !editingPostMode" v-html="post_text">
        </div>
        <div class="text-center mt-2" v-if="post.text !== null && post.text.length > 600 && !editingPostMode">
          <v-btn
            class="textTransformNone"
            color="primary" small outlined
            @click="showFullText = !showFullText">
            {{ showFullText ? 'Show Less' : 'Show More' }}
          </v-btn>
        </div>
        <div v-if="editingPostMode">
          <v-textarea
            class="mt-2 elevation-0"
            v-model="post_input"
            solo
            flat
            no-resize
            placeholder="Write something..."
            hide-details>
          </v-textarea>
          <v-btn
            class="mt-2"
            block
            small
            color="secondary"
            @click="confirmEditPost">
            {{ $vuetify.lang.t('$vuetify.general.confirm') }}
          </v-btn>
        </div>
        <div v-if="post.post_type === 1" class="mt-2" style="cursor: pointer;"
          @click="$emit('image-click', $event, post.image.original)">
          <v-img
            v-if="post.image !== null"
            :src="post.image.thumb_lg"
            height="400">
          </v-img>
        </div>
        <div v-if="post.post_type === 3" class="mt-2">
          <v-card dark class="elevation-0">
            <Player theme="dark"
              style="--vm-control-scale: 1.1;">
              <Video cross-origin="">
                <source :data-src="post.file" />
              </Video>
              <default-ui></default-ui>
            </Player>
          </v-card>
        </div>
        <div v-if="post.post_type === 4" class="mt-2">
          <v-card :elevation="4">
            <v-card-text class="py-2">
              <v-list-item dense @click="openInNewTab(post.file)">
                <v-list-item-title class="font-15 primary--text bold">
                  {{ $vuetify.lang.t('$vuetify.posts.view_document') }}
                </v-list-item-title>
              </v-list-item>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="post.reactions_count > 0" class="font-13 mt-2" style="cursor: pointer;" 
          @click="$emit('likers-click')">
          <v-avatar :size="20" color="primary">
            <v-icon x-small color="white">mdi-thumb-up</v-icon>
          </v-avatar>
          <a class="like-count-a primary--text bold ms-1">
            {{ post.reactions_count }}
          </a>
        </div>
      </v-card-text>
      <v-card-actions class="py-0 mb-0 mt-2">
        <v-btn text small @click="toggleLike" color="primary">
          <v-icon left>
            {{ post.did_user_react ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
          </v-icon>
          {{ $vuetify.lang.t('$vuetify.posts.like') }}
        </v-btn>
        <v-btn text small color="primary" @click="showComments = !showComments">
          <v-icon left>
            mdi-comment-outline
          </v-icon>
          {{ $vuetify.lang.t('$vuetify.posts.comments') }} ({{ post.comments_count }})
        </v-btn>
      </v-card-actions>
      <v-list-item @click="$router.push({ name: 'VoomView', query: {vid: post.voom.id} })"
        v-if="showVoom">
        <v-list-item-avatar>
          <v-avatar color="primary" :size="40">
            <img v-if="post.voom.image === null" src="@/assets/no_image.jpg" />
            <img v-else :src="post.voom.image.thumb_sm" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-14 bold">
            {{ post.voom.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="mx-5" v-if="showComments">
        <v-divider></v-divider>
        <div class="mt-4">
          <div class="font-14 bold">
            {{ $vuetify.lang.t('$vuetify.posts.comments') }}
          </div>
          <div class="px-4 pb-3">
            <post-comment
              v-for="comment in comments"
              :key="comment.id"
              :value="comment"
              color="card_color"
              @updated="getComments"
              class="mt-3">
            </post-comment>
          </div>
          <v-row class="me-3" align="center">
            <v-col cols="10">
              <v-textarea
                type="text"
                outlined
                dense
                rows="1"
                no-resize
                auto-grow
                hide-details
                v-model="comment_text"
                :label="$vuetify.lang.t('$vuetify.posts.write_comment')">
                <template v-slot:append>
                  <twemoji-picker
                    :emojiData="emojiDataAll"
                    :emojiGroups="emojiGroups"
                    :skinsSelection="false"
                    :searchEmojisFeat="true"
                    searchEmojiPlaceholder="Search here."
                    searchEmojiNotFound="Emojis not found."
                    @emojiUnicodeAdded="insertEmojiToComment"
                    :isLoadingLabel="$vuetify.lang.t('$vuetify.general.loading')">
                    <template v-slot:twemoji-picker-button>
                      <v-btn icon small>
                        <v-icon>
                          mdi-emoticon
                        </v-icon>
                      </v-btn>
                    </template>    
                  </twemoji-picker>
                </template>
              </v-textarea>
            </v-col>
            <v-col cols="2">
              <v-btn rounded color="primary" small depressed class="textTransformNone"
                @click="createComment">
                {{ $vuetify.lang.t('$vuetify.general.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json'
  import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json'
  import { Player, Video, DefaultUi } from '@vime/vue'
  export default {
    props: {
      value: Object,
      showVoom: {
        type: Boolean,
        default: true
      },
      commentsShown: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isLoading: false,
        post_input: '',
        editingPostMode: false,
        showComments: false,
        comments: [],
        comment_text: '',
        showFullText: false
      }
    },
    components: {
      Player,
      Video,
      DefaultUi
    },
    watch: {
      showComments (value) {
        if (value) {
          this.getComments()
        }
      },
      value (value) {
        if (this.showComments) {
          this.getComments()
        }
      }
    },
    computed: {
      post: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      allowedDeletionOnly () {
        if (this.$store.getters.user.account_type === 1) {
          return this.hasRoleActivity('vooms', '3') || this.post.instructor_id === this.$store.getters.creator.id
        }
        return false
      },
      emojiDataAll() {
        return EmojiAllData;
      },
      emojiGroups() {
        return EmojiGroups;
      },
      post_text () {
        if (this.showFullText) {
          return this.detectUrls(this.post.text)
        }
        return this.detectUrls(this.post.text.substring(0, 600))
      }
    },
    methods: {
      insertEmojiToComment (emoji) {
        this.comment_text += emoji
      },
      toggleLike() {
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        if (this.post.did_user_react) {
          this.post.did_user_react = false
          this.post.reactions_count -= 1
        } else {
          this.post.did_user_react = true
          this.post.reactions_count += 1
        }
        this.$http.put(this.basic_url + '/social_api/posts/reactions/' + this.post.id + '/', {}, auth_header).then(response => {
        }, error => {
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      reloadPost() {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/posts/' + this.post.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.post = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      editPost () {
        this.post_input = this.post.text !== null ? this.post.text : ''
        this.editingPostMode = true
      },
      confirmEditPost () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.put(this.basic_url + '/social_api/posts/' + this.post.id + '/', {text: this.post_input}, auth_header).then(response => {
          this.isLoading = false
          this.reloadPost()
          this.post_input = ''
          this.editingPostMode = false
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      getComments() {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/posts/comments/' + this.post.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.comments = response.body
          this.post.comments_count = this.comments.length
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      createComment () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.post(this.basic_url + '/social_api/posts/comments/?post_id=' + this.post.id, {text: this.comment_text}, auth_header).then(response => {
          this.isLoading = false
          this.comment_text = ''
          this.getComments()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      if (this.commentsShown) {
        this.showComments = true
      }
    }
  }
</script>

<style>
  .like-count-a {
    text-decoration: unset;
  }

  .like-count-a:hover {
    text-decoration: underline;
  }
</style>