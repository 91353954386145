<template>
  <router-link :to="to" class="bar-item-router-link"
    active-class="bar-item-router-link__active"
    exact
    v-if="!btn">
    <div class="bar-item-container">
      <div>
        <v-icon class="bar-item-icon">
          {{ icon }}
        </v-icon>
      </div>
      <div class="bar-item-text">
        {{ text }}
      </div>
    </div>
  </router-link>
  <button v-else v-on="on" :class="{'bar-item-btn': true, 'bar-item-router-link__active': active}">
    <div class="bar-item-container">
      <div>
        <v-icon class="bar-item-icon">
          {{ icon }}
        </v-icon>
      </div>
      <div class="bar-item-text">
        {{ text }}
        <v-icon v-if="textIcon !== null"
          class="bar-item-text-icon">
          {{ textIcon }}
        </v-icon>
      </div>
    </div>
  </button>
</template>

<script>
  export default {
    props: {
      to: String,
      icon: String,
      text: String,
      btn: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
        default: false,
      },
      on: Object,
      textIcon: String
    }
  }
</script>

<style scoped>
  .bar-item-router-link {
    text-decoration: none;
    color: var(--text-color);
    padding-bottom: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .bar-item-router-link__active {
    border-bottom: 2px solid var(--text-color);
  }

  .bar-item-btn {
    padding-bottom: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .bar-item-container {
    display: flex;
    height: 64px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bar-item-icon {
    font-size: 25px;
    color: var(--text-color);
  }
  .bar-item-text-icon {
    color: var(--text-color);
  }

  .bar-item-text {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>