<template>
  <div>
    <v-snackbar
      dark
      color="secondary"
      :timeout="-1"
      :value="true"
      top
    >
      {{ $vuetify.lang.t('$vuetify.account.activation_email_sent') }} <span class="bold">{{ $store.getters.user.email }}</span>. {{ $vuetify.lang.t('$vuetify.account.activation_email_explained') }}
      <template v-slot:action>
        <v-btn
          class="textTransformNone bold"
          small
          text
          @click="resendActivationEmail"
        >
          {{ $vuetify.lang.t('$vuetify.account.resend_email') }}
        </v-btn>
      </template>
    </v-snackbar>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false
      }
    },
    methods: {
      resendActivationEmail () {
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
          }
        }
        this.isLoading = true
        this.$http.post(this.basic_url + '/auth/users/resend_activation_email/', {}, auth_header).then(response => {
          this.isLoading = false
          this.$store.dispatch('setReloadUserInfo', true)
        }, error => {
          this.isLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.errorSnackbar = true
        })
      }
    }
  }
</script>