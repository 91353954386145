<template>
  <div>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-menu v-if="$store.getters.user !== null" left class="text-center" close-on-content-click :nudge-width="200" :max-height="500">
     <template v-slot:activator="{ on }">
       <v-btn v-on="on" text icon 
          :light="!$vuetify.theme.dark"
          :dark="$vuetify.theme.dark">
          <v-avatar color="card_color" :size="40">
            <span class="font-17 bold primary_text--text" v-if="$store.getters.user.image === null">
              {{ $store.getters.user.name.substring(0, 1) }}
            </span>
            <img v-else :src="$store.getters.user.image.thumb_sm" />
          </v-avatar>
       </v-btn>
     </template>
     <v-list>
       <v-list-item @click="goToProfile">
         <v-list-item-avatar>
           <v-avatar color="primary" :size="40">
            <span class="white--text font-17" v-if="$store.getters.user.image === null">
              {{ $store.getters.user.name.substring(0, 1) }}
            </span>
            <img v-else :src="$store.getters.user.image.thumb_sm" />
          </v-avatar>
         </v-list-item-avatar>
         <v-list-item-content>
           <v-list-item-title class="font-14 bold">{{ $store.getters.user.name }}</v-list-item-title>
           <span class="font-13">
             {{ this.$vuetify.lang.t('$vuetify.account.view_profile') }}
           </span>
         </v-list-item-content>
       </v-list-item>
       <v-list-item v-if="darkModeEnabled">
        <v-switch
          v-model="dark_mode"
          hide-details
          class="my-auto"
          dense
          :label="$vuetify.lang.t('$vuetify.app_bar.dark_mode')"
        ></v-switch>
      </v-list-item>
      <v-list-item @click="goToUrl('/user/accounts')" v-if="!is_custom_domain">
        <v-list-item-content>
          <v-list-item-title class="font-13 bold">
            {{ this.$vuetify.lang.t('$vuetify.account.my_accounts') }}
          </v-list-item-title>
        </v-list-item-content>
       </v-list-item>
      <v-list-item @click="logoutUser">
        <v-list-item-content>
          <v-list-item-title class="font-13 bold">
            {{ this.$vuetify.lang.t('$vuetify.account.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     </v-list>
    </v-menu>
  </div>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    props: {
      darkModeEnabled: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isLoading: false,
        constants: null
      }
    },
    watch: {
      dark_mode (value) {
        this.$store.dispatch('setDarkMode', value)
        this.updateUserPreferences({is_dark_theme: value})
      }
    },
    computed: {
      dark_mode: {
        get () {
          return this.$vuetify.theme.dark
        },
        set (value) {
          this.$vuetify.theme.dark = value
        }
      }
    },
    methods: {
      goToUrl (url) {
        window.location.href = url
      },
      logoutLocally () {
        this.$cookies.remove(constants.cookies.TOKEN, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.SESSION_ID, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.CURRENT_ACCOUNT_ID, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.ED_ACCOUNT_TYPE, null, constants.COOKIES_DOMAIN_NAME)
        this.$cookies.remove(constants.cookies.CREATOR_ACCOUNT_ID, null, constants.COOKIES_DOMAIN_NAME)
        window.location.href = constants.LOGIN_URL
      },
      logoutUser () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        this.$http.post(this.basic_url + '/auth/users/logout/', {}, auth_header).then(response => {
          this.isLoading = false
          this.logoutLocally()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized ) {
            this.logoutLocally()
            window.location.href = constants.LOGIN_URL
          }
        })
      },
      goToProfile () {
        if (!this.is_custom_domain) {
          window.location.href = '/user/profile'
        } else {
          if (this.$store.getters.user.account_type === 0) {
            this.$router.push('/learning/user/profile')
          } else {
            this.$router.push('/creator/user/profile')
          }
        }
      }
    },
    created () {
      this.constants = constants
    }
  }
</script>