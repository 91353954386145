<template>
  <div>
    <new-conversation-dialog
      v-model="newConversationDialog"
      @updated="conversationAdded">    
    </new-conversation-dialog>
    <div :class="{'chatting-interface': !$vuetify.rtl, 'chatting-interface-rtl': $vuetify.rtl}"
      style="z-index: 200;">
      <v-list-item class="app-gradient elevation-12 rounded-lg" dark style="min-width: 100px; width: 300px;"
        @click="isConversationsPanelOpen = !isConversationsPanelOpen">
        <v-list-item-avatar v-if="$store.getters.user !== null" color="card_color">
          <span class="font-17 primary_text--text" v-if="$store.getters.user.image === null">
            {{ $store.getters.user.name.substring(0, 1) }}
          </span>
          <img v-else :src="$store.getters.user.image.thumb_sm" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-15 bold">
            {{ $vuetify.lang.t('$vuetify.messaging.title') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card
        style="height: 70vh; overflow-y: scroll; overflow-x: hidden;"
        v-if="isConversationsPanelOpen">
        <div class="mx-3 my-1">
          <v-btn color="secondary" small block
            @click="newConversationDialog = true">
            <v-icon left>
              mdi-pencil-box
            </v-icon>
            {{ $vuetify.lang.t('$vuetify.messaging.start_conversation') }}
          </v-btn>
        </div>
        <v-list dense>
          <v-list-item v-for="item in conversations" :key="item.id" @click="openConversation(item)"
            style="max-width: 280px;">
            <v-list-item-avatar color="primary" v-if="item.room_type === 1">
              <span class="font-17 white--text" v-if="item.chatting_with.image === null">
                {{ item.chatting_with.name.substring(0, 1) }}
              </span>
              <img v-else :src="item.chatting_with.image.thumb_lg" />
            </v-list-item-avatar>
            <v-list-item-avatar color="primary" v-else>
              <span class="font-17 white--text" v-if="item.image === null">
                {{ item.name.substring(0, 1) }}
              </span>
              <img v-else :src="item.image.thumb_sm" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-15">
                {{ item.room_type !== 1 ? item.name : item.chatting_with.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-15" v-if="item.last_message !== null">
                {{ item.last_message.sender.name.split(' ')[0] + ':' }} {{ item.last_message.text }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div>
      <conversation-tab
        v-for="(item, i) in opened_conversations"
        :key="item.id"
        :value="item"
        :index="i + 1"
        @close-conversation="closeConversation">
      </conversation-tab>
    </div>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        isConversationsPanelOpen: false,
        conversations: [],
        opened_conversations: [],
        newConversationDialog: false
      }
    },
    watch: {
      isConversationsPanelOpen (value) {
        if (value) {
          this.getConversations()
        }
      },
      open_conversation (value) {
        if (value !== null) {
          this.retrieveConversation(value)
          var query = Object.assign({}, this.$route.query)
          query.open_conversation = undefined
          this.$router.push({ query: query })
        }
      }
    },
    computed: {
      open_conversation () {
        if (this.$route.query.open_conversation !== undefined) {
          return this.$route.query.open_conversation
        }
        return null
      }
    },
    methods: {
      conversationAdded (item) {
        this.openConversation(item)
        this.getConversations()
      },
      getConversations () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/conversations/', auth_header).then(response => {
          this.isLoading = false
          this.conversations = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      retrieveConversation (id) {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/conversations/' + id + '/', auth_header).then(response => {
          this.isLoading = false
          this.openConversation(response.body)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      isConversationOpen (item) {
        for (var i = 0; i < this.opened_conversations.length; i++) {
          if (this.opened_conversations[i].id === item.id) {
            return true
          }
        }
        return false
      },
      openConversation (item) {
        if (!this.isConversationOpen(item)) {
          if (this.$vuetify.breakpoint.mdAndUp) {
            this.opened_conversations.push(Object.assign({}, item))
          } else {
            this.$router.push({name: 'MobileChatting', query: {cid: item.id}})
          }
        }
      },
      closeConversation (e, item) {
        if (this.isConversationOpen(item)) {
          for (var i = 0; i < this.opened_conversations.length; i++) {
            if (this.opened_conversations[i].id === item.id) {
              this.opened_conversations.splice(i, 1)
              break
            }
          }
        }
      }
    }
  }
</script>

<style>
  .chatting-interface {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .chatting-interface-rtl {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
</style>