<template>
  <div>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-menu
      v-model="menu"
      left
      :nudge-width="200">
      <template v-slot:activator="{ on }">
        <v-btn icon class="me-3" small v-on="on">
          <v-badge
            v-if="notSeenCount > 0"
            color="red darken-1"
            :content="notSeenCount"
            overlap
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list dense v-if="notification_res !== null">
        <div class="font-13 bold my-3 mx-3">
          {{ $vuetify.lang.t('$vuetify.notifications.title') }}
        </div>
        <v-list-item v-for="(notif, key) in notification_res.data" :key="key" 
          @click="notificationClick(notif)"
          style="max-width: 550px;"
          :class="!notif.is_seen ? 'blue-grey darken-3' : ''" :dark="!notif.is_seen">
          <v-list-item-avatar>
            <img :src="(notif.voom !== null && notif.voom.image !== null) ? notif.voom.image.thumb_sm : require('@/assets/no_image.jpg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-15">
              {{ notif.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-14">
              {{ notif.message }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-13 bold">
              {{ timeSince(notif.created_at) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-btn text block @click="$router.push({name: 'NotificationsPage'})">
            {{ $vuetify.lang.t('$vuetify.notifications.see_all') }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        menu: false,
        notification_res: null
      }
    },
    watch: {
      menu (value) {
        if (value) {
          this.getNotifications()
        } else {
          if (this.notSeenCount > 0) {
            this.markRead()
          }
        }
      },
      refreshNotifications (value) {
        if (value) {
          this.getNotifications()
        }
      }
    },
    computed: {
      notSeenCount () {
        if (this.notification_res === null) {
          return 0
        }

        var count = 0
        for (var i = 0; i < this.notification_res.data.length; i++) {
          if (!this.notification_res.data[i].is_seen) {
            count++
          }
        }
        return count
      },
      refreshNotifications: {
        get () {
          return this.$store.getters.refreshNotifications
        },
        set (value) {
          this.$store.dispatch('setRefreshNotifications', value)
        }
      }
    },
    methods: {
      getNotifications (withLoading = true) {
        this.isLoading = withLoading
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            Language: this.$store.getters.lang,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/notifications/?list_type=express', auth_header).then(response => {
          this.isLoading = false
          this.notification_res = response.body
          this.refreshNotifications = false
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      markRead () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.post(this.basic_url + '/social_api/notifications/mark_seen/', {}, auth_header).then(response => {
          this.isLoading = false
          this.getNotifications()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    mounted () {
      // this.$nextTick(function () {
      //   window.setInterval(() => {
      //       this.getNotifications(false)
      //   }, 300000)
      // })
    },
    created () {
      this.getNotifications()
    }
  }
</script>