<template>
  <div>
    <v-card
      class="chat-window"
      :style="$vuetify.rtl ? 'left: ' + (index * 320 + 40 * (index - 1)) + 'px;' : 'right: ' + (index * 320 + 40 * (index - 1)) + 'px;'">
      <v-toolbar
        color="primary"
        dark
        dense>
        <v-avatar color="card_color" :size="35" style="cursor: pointer;"
          @click="isChatWindowOpen = !isChatWindowOpen"
          v-if="value.room_type === 1">
          <span class="font-17 primary_text--text" v-if="value.chatting_with.image === null">
            {{ value.chatting_with.name.substring(0, 1) }}
          </span>
          <img v-else :src="value.chatting_with.image.thumb_lg" />
        </v-avatar>
        <v-avatar color="card_color" :size="35" style="cursor: pointer;"
          @click="isChatWindowOpen = !isChatWindowOpen"
          v-else>
          <span class="font-17 primary_text--text" v-if="value.image === null">
            {{ value.name.substring(0, 1) }}
          </span>
          <img v-else :src="value.image.thumb_sm" />
        </v-avatar>
        <v-toolbar-title class="font-17 ms-3"
          style="cursor: pointer;"
          @click="isChatWindowOpen = !isChatWindowOpen">
          {{ value.room_type !== 1 ? value.name : value.chatting_with.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('close-conversation', $event, value)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <chatting-general-component
        :value="value"
        v-if="isChatWindowOpen">
      </chatting-general-component>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      value: Object,
      index: Number
    },
    data () {
      return {
        isChatWindowOpen: true,
      }
    }
  }
</script>

<style>
  .chat-window {
    position: fixed;
    z-index: 100;
    width: 90%;
    max-width: 350px;
    bottom: 0px;
    animation: fadeIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    100% {
      display: flex;
      opacity: 1;
    }
  }
</style>