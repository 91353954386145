<template>
  <div>
    <v-dialog v-model="isOpen" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="font-17 bold">
          <span>
            {{ $vuetify.lang.t('$vuetify.posts.liked_by') }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon small @click="isOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-list dense>
            <v-list-item v-for="(like , key) in likers" :key="key">
              <v-list-item-avatar :size="35" color="primary">
                <span class="white--text font-17" v-if="like.user_data.image === null">
                  {{ like.user_data.name.substring(0, 1) }}
                </span>
                <img v-else :src="like.user_data.image.thumb_lg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-16">
                  {{ like.user_data.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      postId: Number
    },
    data () {
      return {
        isLoading: false,
        likers: []
      }
    },
    watch: {
      isOpen (value) {
        if (value) {
          this.getLikers()
        } else {
          this.likers = []
        }
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      getLikers () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.get(this.basic_url + '/social_api/posts/reactions/' + this.postId + '/', auth_header).then(response => {
          this.isLoading = false
          this.likers = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    }
  }
</script>