export default {
  COOKIES_DOMAIN_NAME: '.smartvoom.com',
  SV_CMS_URL: 'http://www.smartvoom.com',
  PIETECHS_URL: 'http://www.pietechs.com',
  LOGIN_URL: '/login',
  cookies: {
    LANG: '_SV_lang',
    TOKEN: '_SV_token',
    SESSION_ID: '_SV_session_id',
    CURRENT_ACCOUNT_ID: '_SV_vs_account_id',
    ED_ACCOUNT_TYPE: '_SV_vs_account_type',
    CREATOR_ACCOUNT_ID: '_SV_creator_account'
  }
}
