<template>
  <v-dialog v-model="isOpen" max-width="500px" content-class="noOverflow" persistent hide-overlay>
    <v-card>
      <div class="text-center py-3 px-3">
        <v-progress-linear
          v-model="progress"
          color="primary"
          height="25"
        ></v-progress-linear>
        <div class="mt-3">
          <span class="loadingText dark--text bold">
            {{ '' + progress + '%' }}
          </span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'linear-loading-dialog',
    props: {
      value: Number
    },
    data () {
      return {
        isOpen: true
      }
    },
    computed: {
      progress: {
        get () {
          return this.value
        },
        set (progress) {
          this.$emit('input', progress)
        }
      }
    }
  }
</script>

<style>
  .loadingText {
    font-size: 14px;
  }
  .noOverflow{
    overflow-y: visible !important;
  }
</style>