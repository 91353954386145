<template>
  <div>
    <yes-no-dialog
      v-if="deleteCommentDialog"
      v-model="deleteCommentDialog"
      :title="$vuetify.lang.t('$vuetify.posts.delete_comment')"
      :text="$vuetify.lang.t('$vuetify.posts.delete_comment_confirm')"
      @yes-clicked="deleteComment">
    </yes-no-dialog>
    <v-card
      color="card_color"
      shaped>
      <v-list-item>
        <v-list-item-avatar :size="35" color="primary">
          <span class="white--text font-17" v-if="comment.user_data.image === null">
            {{ comment.user_data.name.substring(0, 1) }}
          </span>
          <img v-else :src="comment.user_data.image.thumb_lg" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-14 bold">
            {{ comment.user_data.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-14">
            {{ timeSince(comment.created_at) }}.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu
            v-if="comment.user_data.id === $store.getters.user.account_id || allowedDeletionOnly"
            bottom
            :nudge-width="80"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editComment"
                v-if="comment.user_data.id === $store.getters.user.account_id">
                <v-list-item-content>
                  <v-list-item-title class="font-13 bold">
                    {{ $vuetify.lang.t('$vuetify.creators.activity_edit') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="deleteCommentDialog = true"
                v-if="comment.user_data.id === $store.getters.user.account_id || allowedDeletionOnly">
                <v-list-item-content>
                  <v-list-item-title class="font-13 bold">
                    {{ $vuetify.lang.t('$vuetify.creators.activity_delete') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-card-text v-if="!editingMode" class="font-14 pt-0 secondary_text--text" style="white-space: pre-wrap;" v-html="comment_text">
      </v-card-text>
      <div class="mx-3 pb-3" v-else>
        <v-textarea
          type="text"
          outlined
          dense
          rows="1"
          no-resize
          auto-grow
          hide-details
          v-model="comment_input"
          :label="$vuetify.lang.t('$vuetify.posts.write_comment')">
          <template v-slot:append>
            <twemoji-picker
              :emojiData="emojiDataAll"
              :emojiGroups="emojiGroups"
              :skinsSelection="false"
              :searchEmojisFeat="true"
              searchEmojiPlaceholder="Search here."
              searchEmojiNotFound="Emojis not found."
              @emojiUnicodeAdded="insertEmoji"
              isLoadingLabel="Loading...">
              <template v-slot:twemoji-picker-button>
                <v-btn icon small>
                  <v-icon>
                    mdi-emoticon
                  </v-icon>
                </v-btn>
              </template>    
            </twemoji-picker>
          </template>
        </v-textarea>
        <v-btn rounded block color="primary" small depressed class="mt-3"
          @click="confirmEditComment">
          {{ $vuetify.lang.t('$vuetify.general.confirm') }}
        </v-btn>
      </div>
      <div class="text-center mt-2" v-if="comment.text.length > 300 && !editingMode">
        <v-btn
          class="textTransformNone mb-3"
          color="primary" small outlined
          @click="showFullText = !showFullText">
          {{ showFullText ? 'Show Less' : 'Show More' }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json'
  import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json'
  export default {
    props: {
      value: Object
    },
    data () {
      return {
        isLoading: false,
        editingMode: false,
        comment_input: '',
        deleteCommentDialog: false,
        isError: false,
        errorMessage: '',
        showFullText: false
      }
    },
    computed: {
      comment () {
        return this.value
      },
      emojiDataAll() {
        return EmojiAllData;
      },
      emojiGroups() {
        return EmojiGroups;
      },
      comment_text () {
        if (this.showFullText) {
          return this.detectUrls(this.comment.text)
        }
        return this.detectUrls(this.comment.text.substring(0, 300))
      },
      allowedDeletionOnly () {
        if (this.$store.getters.user.account_type === 1) {
          return this.hasRoleActivity('vooms', '3') || this.comment.instructor_id === this.$store.getters.creator.id
        }
        return false
      },
    },
    methods: {
      insertEmoji (emoji) {
        this.comment_input += emoji
      },
      editComment () {
        this.comment_input = this.comment.text
        this.editingMode = true
      },
      confirmEditComment() {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.put(this.basic_url + '/social_api/posts/comments/' + this.comment.id + '/', {text: this.comment_input}, auth_header).then(response => {
          this.isLoading = false
          this.editingMode = false
          this.comment_input = ''
          this.$emit('updated')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      deleteComment () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
            'Account-Id': this.$store.getters.login_data.account_id
          }
        }
        this.$http.delete(this.basic_url + '/social_api/posts/comments/' + this.comment.id + '/', auth_header).then(response => {
          this.isLoading = false
          this.$emit('updated')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    }
  }
</script>